import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { dictionaryList } from "../../languages/index";

import CustomSelect from "../base/CustomSelect";
import { CallApi } from '../../Api';

function FastDonationModal({ append = false }) {
  const [show, setShow] = useState(append);
  const [donationValue, setDonationValue] = useState(50);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const lang = localStorage.getItem("rcml-lang");
  const history = useHistory();

  useEffect(() => {
    async function fetchCategories() {
      try {
        const response = await CallApi("GET", `/api/categories?locale=${lang}`);
        setCategories(response.data);
      } catch (error) {
        // Ignore Error Message
      }
    }

    categories.length === 0 && fetchCategories();
  }, [])
  
  const handleShow = () => setShow(true);

  const handleClose = () => setShow(false);

  const handleSubmit = () => {
    window.gtag('event', 'fast_donation_button', {'send_to': 'G-PD3S9TK0V8'});
    handleClose();
    history.push(`/${lang}/${selectedCategory}/main-payment`, { sentAmount:  donationValue, isProject: false, category: categories.find((ctg) => ctg.id === selectedCategory) });
  }

  const handleDonationChange = (event) => setDonationValue(event.target.value);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category ? category.value : null);
  };

  return (
    <div>
      {
        !append && (
          <Button className="btn btn-success fast-donate" onClick={handleShow}>
            <span>{dictionaryList[lang].fast_donation}</span>
            <i className="fa fa-paper-plane mx-1"></i>
          </Button>
        )
      }

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{dictionaryList[lang].fast_donation}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className={`${lang === 'ar' ? 'direction-rtl text-right' : 'direction-ltr text-left'}`}>
            <div className="my-3">
              <label htmlFor="category" className="mb-2">{dictionaryList[lang].donation_type}</label>
              <CustomSelect
                value={selectedCategory}
                placeholder={dictionaryList[lang].select}
                onChange={handleCategoryChange}
                options={categories?.map((category) => ({
                  value: category.id,
                  label: category.name,
                }))}
                id="category"
                className="category-input w-100"
                isClearable={true}
              />
            </div>
            <div className="my-3">
              <label htmlFor="donation-amount" className="mb-2">{dictionaryList[lang].donation_amount}</label>
              <input type="text" id="donation-amount" className="form form-control" placeholder="$$" value={donationValue} onChange={handleDonationChange} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-success" onClick={handleSubmit} disabled={!selectedCategory || !Number(donationValue) > 0}>
            {dictionaryList[lang].donate_now}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default FastDonationModal;
