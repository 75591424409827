import React from 'react'

const WhatsAppWidget = () => {
  const [switchDisplay, setSwitchDisplay] = React.useState(true);

  const handleSwitch = () => setSwitchDisplay(!switchDisplay);

  const onRedirectEvent = () => {
      window.gtag('event', 'main_whatsapp_button', {'send_to': 'G-PD3S9TK0V8'});
  }

  return (  
    <div id="whatsapp_chat_widget" onClick={handleSwitch}>
            <div id="wa-widget-send-button">
              <i className="fab fa-whatsapp text-white color-white" style={{ fontSize: 22 }}></i>
              <div></div>
            </div>
        
            <div className={`wa-chat-box ${switchDisplay ? 'd-none' : 'd-block'}`}>
                <div className="wa-chat-box-header">
                    <img className="wa-chat-box-brand" src="https://admin.sadaqahti.com/storage/188/%D8%B5%D8%BA%D9%8A%D8%B1.png" />
                    <div className="wa-chat-box-brand-text">
                        <div className="wa-chat-box-brand-name">Sadaqahti Foundation - مؤسسة صدقاتي</div>
                        <div className="wa-chat-box-brand-subtitle">We support Muslims and the poor in Africa - ندعم المسلمين والفقراء في أفريقيا</div>
                    </div>
                    <div className="wa-chat-bubble-close-btn">
                      <img  src="https://cdn.shopify.com/s/files/1/0070/3666/5911/files/Vector.png?574" />
                    </div>
                </div>
                
                <div className="wa-chat-box-content">
                    <div className="wa-chat-box-content-chat">                            
                        <div className="wa-chat-box-content-chat-brand">مؤسسة صدقاتي أفريقيا ⁦+265997650775⁩</div>
                        <div className="wa-chat-box-content-chat-welcome">Alsalam ealaykum<br/>Please leave your question and it will be answered within the day<br/>Jazakum Allah khair<br/>من فضلك اترك سؤالك وسيتم الإجابة عليه على مدار اليوم</div>
                    </div>
                </div>
                
                <div className="wa-chat-box-send">
                    <a 
                      target="_blank" 
                      href="https://api.whatsapp.com/send?phone=265997650775&amp;text=Please I want to inquire about%0Aمن فضلك أريد أن أستفسرعن%0A %0A"
                      title="WhatsApp" 
                      className="wa-chat-box-content-send-btn"
                      onClick={onRedirectEvent}
                    >
                        <svg width="20" height="20" viewBox="0 0 90 90" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="wa-chat-box-content-send-btn-icon"><path d="M90,43.841c0,24.213-19.779,43.841-44.182,43.841c-7.747,0-15.025-1.98-21.357-5.455L0,90l7.975-23.522   c-4.023-6.606-6.34-14.354-6.34-22.637C1.635,19.628,21.416,0,45.818,0C70.223,0,90,19.628,90,43.841z M45.818,6.982   c-20.484,0-37.146,16.535-37.146,36.859c0,8.065,2.629,15.534,7.076,21.61L11.107,79.14l14.275-4.537   c5.865,3.851,12.891,6.097,20.437,6.097c20.481,0,37.146-16.533,37.146-36.857S66.301,6.982,45.818,6.982z M68.129,53.938   c-0.273-0.447-0.994-0.717-2.076-1.254c-1.084-0.537-6.41-3.138-7.4-3.495c-0.993-0.358-1.717-0.538-2.438,0.537   c-0.721,1.076-2.797,3.495-3.43,4.212c-0.632,0.719-1.263,0.809-2.347,0.271c-1.082-0.537-4.571-1.673-8.708-5.333   c-3.219-2.848-5.393-6.364-6.025-7.441c-0.631-1.075-0.066-1.656,0.475-2.191c0.488-0.482,1.084-1.255,1.625-1.882   c0.543-0.628,0.723-1.075,1.082-1.793c0.363-0.717,0.182-1.344-0.09-1.883c-0.27-0.537-2.438-5.825-3.34-7.977   c-0.902-2.15-1.803-1.792-2.436-1.792c-0.631,0-1.354-0.09-2.076-0.09c-0.722,0-1.896,0.269-2.889,1.344   c-0.992,1.076-3.789,3.676-3.789,8.963c0,5.288,3.879,10.397,4.422,11.113c0.541,0.716,7.49,11.92,18.5,16.223   C58.2,65.771,58.2,64.336,60.186,64.156c1.984-0.179,6.406-2.599,7.312-5.107C68.398,56.537,68.398,54.386,68.129,53.938z"></path></svg>
                        <span className="wa-chat-box-content-send-btn-text">Start Chat - ابدأ المحادثة</span>
                    </a>
                </div>
            </div>
        </div>
  )
}

export default WhatsAppWidget
