import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import poly from "../../img/poly.jpg";

const ReactSlider = ({ images }) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {images === 0 ? (
        <Carousel.Item>
          <img
            className="d-block img-fluid w-100"
            src={poly}
            alt="..."
            style={{ objectFit: "cover" }}
          />
        </Carousel.Item>
      ) : (
        images.map((img, idx) => {
          return (
            <Carousel.Item interval={3000} key={idx}>
              <img
                className="d-block img-fluid w-100"
                src={img.url}
                alt={img.description}
              />
            </Carousel.Item>
          );
        })
      )}
    </Carousel>
  );
};

export default ReactSlider;
