import React from "react";
import SocialLinks from "./SocialLinks";

import { dictionaryList } from "../../languages/index";

export default function TopNavbar() {
  const lang = localStorage.getItem("rcml-lang");
  return (
    <nav 
      className="navbar navbar-expand-lg navbar-light fixed-top main-nav d-none d-lg-block p-0" 
      style={{ backgroundColor: '#74CABE', color: '#fff' }}
    >
      <div className="container flex-nowrap">
        <div className="collapse navbar-collapse d-none d-lg-block" id="navbarSupportedContent">
          <ul className={`navbar-nav text-center ${lang === 'ar' ? 'ml-auto' : 'mr-auto'}`}>
            <li className="nav-item mx-2">
              <i className="fa fa-phone fa-flip-horizontal mx-2"></i>
              <a dir="ltr" href="tel:265997650775">+265997650775</a>
            </li>
            <li className="nav-item mx-2">
              <i className="fa fa-at mx-2"></i>
              <a href="mailto:support@sadaqahti.com">support@sadaqahti.com</a>
            </li>
            <li className="nav-item mx-2">
              <i className="fa fa-location-arrow mx-2"></i>
              <span>{dictionaryList[lang].address}</span>
            </li>
          </ul>
          <ul className={`navbar-nav ${lang === 'ar' ? 'mr-auto' : 'ml-auto'}`}>
            <SocialLinks />
          </ul>
        </div>
      </div>
    </nav>
  );
}
