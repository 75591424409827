import React, { useState } from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import ShareIcons from "../base/ShareIcons";

import { dictionaryList } from "./../../languages/index";
import { getCurrencyExchange } from "../../helpers/getCurrencyExchange";
import { calculateProjectDonation } from "../../helpers/calculateProjectDonation";

import DonateInput from '../base/DonateInput';

const ProjectSummary = ({ 
  name, 
  description, 
  slug, 
  projectShortcuts, 
  cost, 
  collected = 0, 
  coverMedia, 
  id, 
  is_general,
  className = 'col-lg-4 col-md-6 col-sm-12 col-xs-12',
  width = '',
  is_gift,
                          is_finished,
}) => {
  const [selectedShortcutAmount, setSelectedShortcutAmount] = useState(0);

  const lang = localStorage.getItem("rcml-lang");
  
  const { percentage, remaining } = calculateProjectDonation(cost, collected);
  
  let strippedHtml = description ? description.replace(/(<([^>]+)>)/gi, "") : "";
  
  const projectRedirectUrl = slug ? `${slug}-${id}` : id;

  return (
    <div className={className}>
      <div className="project projectSummary">
        <div className="projectPhoto">
          <ShareIcons 
            showShareIcon 
            projectName={name} 
            projectUrl={projectRedirectUrl} 
          />
          <Link to={`/${lang}/sadaqah/${projectRedirectUrl}`}>
            <img data-src={coverMedia?.url} alt={coverMedia?.description} className={`img-responsive lazyloaded ${width}`} src={coverMedia?.url} />
          </Link>
        </div>
        <div className="projectTitle">
          <Link to={`/${lang}/sadaqah/${projectRedirectUrl}`}>{name}</Link>
        </div>
        <div className="projectInputs">
          <div className="clearfix mb10 line-clamp-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(strippedHtml) }} />
          <div className="clearfix"></div>
          <div className="mx-1 my-2">
            {is_general === 0 ? (
              <div className="row">
                <div className="col-6">
                  <small className="d-block project_cost">
                    {dictionaryList[lang].project_value}
                  </small>
                  <span className="bg-warning text-small p-1 mt-1 text-center rounded d-block">
                    <strong> 
                      {getCurrencyExchange(cost)}
                      </strong>
                  </span>
                </div>
                <div className="col-6">
                  <small className=" d-block project_remaining">
                    {dictionaryList[lang].remianing}
                  </small>
                  <span className="bg-light-success text-small p-1 mt-1 text-center rounded d-block">
                    <strong>
                      {getCurrencyExchange(remaining)}
                    </strong>
                  </span>
                </div>
              </div>
            ) : null}

            {is_general === 0 ? (
              <div className="progress mt-md-4 mt-3">
                <div
                  className="progress-bar bg-success"
                  style={{ width: percentage >= 1 ? `${percentage}%` : "0" }}
                  role="progressbar"
                  aria-valuenow={percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {`${percentage}%`}
                </div>
              </div>
            ) : null}
          </div>
          <div className="row mx-1 my-2">
            {projectShortcuts?.map((shortcut) => (
              <div 
                key={shortcut.id}
                className="shortcut-square mx-1" 
                onClick={() => setSelectedShortcutAmount(shortcut.amount)}
              >
                {shortcut.name}
              </div>
            ))}
          </div>
          {!is_finished ?
            <DonateInput 
              name={name}
              id={id} 
              sentAmount={selectedShortcutAmount ? getCurrencyExchange(selectedShortcutAmount) : ''}
              is_gift={is_gift}
            /> : null}
        </div>
      </div>
    </div>
  )
};

export default ProjectSummary;
