import React, { Fragment } from "react";
import { dictionaryList } from "./../../languages/index";

import ShareIcons from "../base/ShareIcons";

export default function ShareButtons({ name, id, slug }) {
  const lang = localStorage.getItem("rcml-lang");

  const projectRedirectUrl = slug ? `${slug}-${id}` : id;

  return (
    <Fragment>
      <div className="item mt-4 p-4 py-5 ShareButtons">
        <h4 className="text-center font-weight-bold">{dictionaryList[lang].share}</h4>
        <div className="links mt-5">
          <div className="row ml-3 icon-wrapper">
            <ShareIcons 
              projectUrl={projectRedirectUrl} 
              projectName={name} 
              className="share-block position-relative" 
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
