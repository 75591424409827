import React from "react";
import { List } from "react-content-loader";
import { dictionaryList } from "./../../languages/index";
import { calculateProjectDonation } from "../../helpers/calculateProjectDonation";
import { getCurrencyExchange } from "../../helpers/getCurrencyExchange";

const CostPayment = ({ value, collected, id, loading, isGeneral }) => {
  const lang = localStorage.getItem("rcml-lang");
  const { percentage, remaining } = calculateProjectDonation(value, collected);

  if (loading) {
    return (
      <div className="costPayment p-4">
        <List className="w-100" />
      </div>
    )
  }

  if (isGeneral === 0) {
    return (
      <div className="costPayment p-4">
        <h4 className="text-white font-weight-bold">{dictionaryList[lang].cost_details}</h4>
        <div className="row">
          <div className="col-6">
            <small className="text-white d-block">{dictionaryList[lang].project_value}</small>
            <span className="bg-warning text-small p-1 mt-1 text-center rounded d-block">
              <strong> 
                {getCurrencyExchange(
                  value, 
                  localStorage.getItem('currency'), 
                  JSON.parse(localStorage.getItem('currencies'))
                )}
              </strong>
            </span>
          </div>
          <div className="col-6">
            <small className="text-white d-block">{dictionaryList[lang].remianing}</small>
            <span className="bg-light-success text-small p-1 mt-1 text-center rounded d-block">
              <strong>
                {getCurrencyExchange(
                  remaining, 
                  localStorage.getItem('currency'), 
                  JSON.parse(localStorage.getItem('currencies'))
                )}
              </strong>
            </span>
          </div>
        </div>
        <div className="progress mt-3">
          <div
            className="progress-bar bg-dark"
            style={{ width: percentage >= 1 ? `${percentage}%` : "0" }}
            role="progressbar"
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {`${percentage}%`}
          </div>
        </div>
      </div>
    )
  }

  return null;
};

export default CostPayment;
