import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from "react-toastify";
import { dictionaryList } from "./../../languages/index";
import { CallApi } from "./../../Api/index";
import DOMPurify from "dompurify";
import chat from "../../img/chat.png";
import SocialLinks from '../base/SocialLinks';

toast.configure();

export default function Contact() {
  const [contactContent, setContactContent] = useState([]);
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const lang = localStorage.getItem("rcml-lang");
  const links = JSON.parse(localStorage.getItem("links"));

  useEffect(() => {
    async function fetchContactData() {
      try {
        const response = await CallApi("GET", `/api/content/types/contact_us?locale=${lang}`);
        setContactContent(JSON.parse(response.data.data.content));
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchContactData();
  }, [lang]);

  const onSubmitHandler = async (values, { resetForm }) => {
    try {
      await CallApi("POST", `/api/contact-us`, {}, values);
      toast.success(dictionaryList[lang].contact_success);
      resetForm();
    } catch (error) {
      toast.error("Message didn't submit correctly");
    }
  };

  const contactSchema = Yup.object().shape({
    name: Yup.string().min(3).max(50).required(dictionaryList[lang].name_error),
    email: Yup.string().email("Invalid email").required(dictionaryList[lang].email_error),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required(dictionaryList[lang].phone_error),
    message: Yup.string().required(dictionaryList[lang].message_error),
  });

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      name: "",
      email: "",
      subject: "",
      phone: "",
      message: "",
    },
    validationSchema: contactSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSubmitHandler,
  });

  return (
    <div className="Contact py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="item p-4 mb-md-0 mb-4">
              <h1 className="h3 font-weight-bold">{contactContent ? contactContent.title : ""}</h1>
              <div
                className="projectDescription mb-4"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(contactContent ? contactContent.description : ""),
                }}
              ></div>
              <form id="contactForm" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="projectDescription">
                        {dictionaryList[lang].name}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={dictionaryList[lang].placeholder_name}
                        name="name"
                        id="name"
                        value={values.name}
                        onChange={handleChange}
                      />
                    </div>
                    <p style={{ color: "red" }}>{errors.name ? errors.name : null}</p>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="subject" className="projectDescription">
                        {dictionaryList[lang].subject}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={dictionaryList[lang].placeholder_subject}
                        name="subject"
                        id="subject"
                        value={values.subject}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="name" className="projectDescription">
                        {dictionaryList[lang].email}
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder={dictionaryList[lang].placeholder_email}
                        name="email"
                        id="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                    </div>
                    <p style={{ color: "red" }}>{errors.email ? errors.email : null}</p>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="phone" className="projectDescription">
                        {dictionaryList[lang].phone}
                      </label>
                      <PhoneInput
                        country='eg'
                        value={values.phone}
                        onChange={handleChange}
                        placeholder={dictionaryList[lang].placeholder_phone}
                        
                      />
                    </div>
                    <p style={{ color: "red" }}>{errors.phone ? errors.phone : null}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="message" className="projectDescription">
                    {dictionaryList[lang].message}
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    className="form-control"
                    placeholder={dictionaryList[lang].placeholder_message}
                    rows="6"
                    value={values.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <p style={{ color: "red" }}>{errors.message ? errors.message : null}</p>
                <div className="row mt-5 ">
                  <div className="col-md-3">
                    <button className="btn btn-success btn-block" type="submit">
                      {dictionaryList[lang].submit}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-4">
            <div className="item bg-light-success p-5">
              <img src={chat} className="img-fluid d-block mx-auto " alt="donation" />
            </div>
            <div className="item bg-worning mt-4 p-5 ">
              <p className="text-center font-weight-bold">{dictionaryList[lang].contact_note}</p>
            </div>
            <div className="item mt-4 p-4 py-5">
              <h4 className="text-center font-weight-bold">{dictionaryList[lang].social_media}</h4>
              <div className="links mt-5">
                <div className="row justify-content-center">
                  <SocialLinks classNames="fs-30" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
