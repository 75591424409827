import React from "react";
import { Link } from "react-router-dom";
import { dictionaryList } from "../../languages";

import arrow from "../../img/arrow.png";

export default function Button({ location, state = {}, styleClassName = "", lang, text }) {
  return (
    <Link
      to={{
        pathname: `/${location}`,
        state,
      }}
      className={`btn btn-success ${styleClassName}`}
    >
      {dictionaryList[lang][text]}
      <img src={arrow} className="img-fluid  arrowHeader" alt="donation" />
    </Link>
  );
}
