import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CallApi } from "../../../Api";

import path_title from "../../../img/path_title.svg";

export default function Department({ reference, departemntTitle, description }) {
  const [departments, setDepartments] = useState([]);
  const lang = localStorage.getItem("rcml-lang");

  useEffect(() => {
    async function fetchDepartments() {
      try {
        const response = await CallApi("GET", `/api/categories?locale=${lang}`);
        setDepartments(response.data);
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchDepartments();
  }, [lang]);

  return (
    <div className="Department pb-5 mt-5 element" id="donation" ref={reference}>
      <div className="container">
        <div className="title-section pb-4 d-flex departmentMobile">
          <img src={path_title} className="img-fluid" alt="donation" />
          <div className="content-title departmentContentWrapper">
            <h1 className="h3 sectionTitle ">{departemntTitle}</h1>
            <p>{description}</p>
          </div>
        </div>
        <div className="row mt-5">
          {departments.map((category) => {
            return (
              <div className="col-lg-3 col-sm-6 col-md-4 col-xs-6" key={category.id}>
                <Link
                  to={{
                    pathname: "/sadaqah",
                    state: {
                      categoryId: category.id
                    },
                  }}
                >
                  <div className="item text-center bg-white p-2 mb-2">
                    <img
                      style={{
                        maxHeight: 200,
                        objectFit: "cover",
                      }}
                      src={category.image}
                      className="rounded img-fluid"
                      alt={category.name}
                    />
                    <h6 className="mt-3 line-clamp bold-text" title={category.name}>
                      {category.name}
                    </h6>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
