import React, { useState, useEffect, Suspense } from "react";
import { Link, useLocation } from "react-router-dom";
import { List } from "react-content-loader";
import { Helmet } from 'react-helmet';

import { CallApi } from "./../../Api/index";
import { dictionaryList } from "./../../languages/index";
import InfoProject from "./InfoProject";
import CostPayment from "./CostPayment";
import PublisherInfo from "./PublisherInfo";
import ShareButtons from "./ShareButtons";
import ReactSlider from "./ReactSlider";

import arrow from "../../img/arrow.png";

const SelectDonation = React.lazy(() => import("./SelectDonation"));
const ProjectSummary = React.lazy(() => import("./projectSummary"));

export default function ProjectDetails(props) {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [relatedProjects, setRelatedProjects] = useState(null);
  const projectId = props.match.params.projectId.split('-')[props.match.params.projectId.split('-').length - 1];
  const lang = localStorage.getItem("rcml-lang");
  const location = useLocation();

  async function fetchRelatedProjects() {
    try {
      const response = await CallApi(
        "GET",
        `api/projects?locale=${lang}&include=donations,projectCategory,village.country,coverMedia,galleryMedia,projectShortcuts&filter[disabled]=0&filter[project_category_id]=${project.projectCategory.id}`
      );
      setRelatedProjects(response.data.data.filter((proj) => proj.id !== project.id).splice(0, 2));
    } catch (error) {
      // Ignore Error Message
    }
  }

  async function fetchProject() {
    try {
      setLoading(true);
      const response = await CallApi(
        "GET", 
        `api/projects/${projectId}?locale=${lang}&include=donations,projectCategory,village.country,coverMedia,galleryMedia,projectShortcuts`
      )
      setProject(response.data);
      setLoading(false);
    } catch (error) {
      // Ignore Error Message
    }
  }

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    fetchProject();

    return () => {
      document.getElementsByTagName("html")[0].setAttribute("dir", lang === "ar" ? "rtl" : "ltr" );
      document.getElementsByTagName("html")[0].removeAttribute("data-react-helmet");
    }
  }, [lang, projectId]);

  useEffect(() => {
    fetchRelatedProjects();
  }, [project])

  const stripHtml = (html) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  }

  return (
    <div className="ProjectDetails py-5 ">
      <Helmet>
        <html lang={lang} dir={lang === "ar" ? "rtl" : "ltr"} />
        <title>{project && project.name}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" key="description" content={project && stripHtml(project.description)} />
        <meta name="title" key="title" content={project && project.name} />
        <meta property="og:title" key="og:title" content={project && project.name} />
        <meta property="og:locale" key="og:locale" content={lang} />
        <meta charSet="utf-8" />
        <meta property="og:type" key="og:type" content="website" />
        <meta
          property="og:description"
          key="og:description"
          content={project && stripHtml(project.description)}
        />
        <meta
          property="og:image"
          key="og:image"
          content={project && project.coverMedia ? project.coverMedia?.url : localStorage.getItem("logo")}
        />
        <meta property="twitter:title" key="twitter:title" content={project && project.name} />
        <meta
            property="twitter:description"
            key="twitter:description"
            content={project && stripHtml(project.description)}
        />
        <meta
            property="twitter:image"
            key="twitter:image"
            content={project && project.coverMedia ? project.coverMedia?.url : localStorage.getItem("logo")}
        />
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <ReactSlider
              images={project && project.galleryMedia.length > 0 ? project.galleryMedia : 0}
              loading={loading}
            />
            <InfoProject {...project} />
            {project && project.is_general === 0 ? (
              <PublisherInfo
                location={project && project.village}
                manager={project && project.created_by}
                loading={loading}
              />
            ) : null}
          </div>
          <div className="col-md-4">
            <div className="note bg-light-success  p-4 mb-3">
              <h4 className="font-weight-bold">
                {dictionaryList[lang].project_details_note_title}
              </h4>
              <p className="mb-0 pb-0 font-weight-bold">
                {dictionaryList[lang].project_details_note}
              </p>
            </div>
              <CostPayment
                value={project && project.cost}
                collected={project && project.collected}
                isGeneral={project && project.is_general}
                id={projectId}
                loading={loading}
              />
              <Suspense fallback={<List className="w-100" />}>
                { project && !project.is_finished && (
                  <SelectDonation
                      id={projectId}
                      projectName={project && project.name}
                      shortcuts={project && project.projectShortcuts}
                      loading={loading}
                  />
                )
                }
                {relatedProjects && (
                  <div className="item mt-4 p-4 py-5">
                      <h4 className="text-center font-weight-bold">{dictionaryList[lang].relatedProjects}</h4>
                  <div className="related-projects">
                    {relatedProjects.map((project) => <ProjectSummary { ...project} key={project.id} className="col-sm-12 p-0" width="w-100"/> )}
                    <Link
                      to={{ pathname: `/${lang}/sadaqah` }}
                      className="btn btn-success btn-block"
                    >
                      {dictionaryList[lang].more}
                      <img src={arrow} className="img-fluid ml-3 arrowCompleted" alt="donatio" />
                    </Link>
                  </div>
                  </div>
                )}
              </Suspense>
              <ShareButtons 
                loading={loading} 
                name={project && project.name} 
                id={project && project.id} 
                slug={project && project.slug} 
              />
            </div>
        </div>
      </div>
    </div>
  );
}
