import React, { useState, useEffect } from "react";
import { List } from "react-content-loader";
import DOMPurify from "dompurify";
import { CallApi } from "./../../Api/index";

import vison from "../../img/vison.svg";
import misson from "../../img/misson.svg";

export default function About() {
  const [aboutContent, setAboutContent] = useState(null);
  const [loading, isLoading] = useState(true);
  const lang = localStorage.getItem("rcml-lang");
  const logo = localStorage.getItem("logo");

  useEffect(() => {
    fetchAboutData();
  }, []);

  async function fetchAboutData() {
    try {
      const response = await CallApi("GET", `/api/content/types/about_us?locale=${lang}`);
      setAboutContent(JSON.parse(response.data.data.content));
      isLoading(false);
    } catch (error) {
      // Ignore Error Message
      isLoading(false);
    }
  }

  return (
    <div className="About py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="item p-4 mb-4 d-sm-block d-xs-block d-md-none">
              {loading ? (
                <List className="w-100" height={50} />
              ) : (
                <img
                  src={logo}
                  className="img-fluid d-block mx-auto"
                  width="160"
                  height="52"
                  alt="donation"
                  style={{ width: 160, height: 52, objectFit: "cover" }}
                />
              )}
            </div>
            <div className="item p-4 mb-md-0 mb-4 ">
              {loading ? (
                <List className="w-100" height={200} />
              ) : (
                <>
                  <h1 className="h3 sectionTitle mb-2">{aboutContent ? aboutContent.title : ""}</h1>
                  <div
                    className="projectDescription"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(aboutContent ? aboutContent.description : ""),
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="item p-4 d-none d-md-block">
              {loading ? (
                <List className="w-100" height={50} />
              ) : (
                <img
                  src={logo}
                  className="img-fluid d-block mx-auto"
                  width="160"
                  height="52"
                  alt="donation"
                  style={{ width: 160, height: 52, objectFit: "cover" }}
                />
              )}
            </div>
            <div className="item mt-3 p-4">
              {loading ? (
                <List className="w-100" />
              ) : (
                <>
                  <h1 className="h3 sectionTitle">
                    {aboutContent ? aboutContent.value_title : ""}
                  </h1>
                  <div
                    className="projectDescription"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(aboutContent ? aboutContent.value : ""),
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-5 mt-4">
            <div className="item p-4 mb-md-0 mb-4">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="h3 sectionTitle">
                    {aboutContent ? aboutContent.vision_title : ""}
                  </h1>
                  <p className="col-md-4 projectDescription">
                    {aboutContent ? aboutContent.vision_subtitle : ""}
                  </p>
                </div>

                <div className="col-md-4 projectDescription">
                  <img src={vison} className="img-fluid" alt="donation" />
                </div>
              </div>

              {loading ? (
                <List className="w-100" />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(aboutContent ? aboutContent.vision : ""),
                  }}
                ></div>
              )}
            </div>
          </div>
          <div className="col-md-7 mt-4">
            <div className="item p-4">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="h3 sectionTitle">
                    {aboutContent ? aboutContent.mission_title : ""}
                  </h1>
                  <p className="projectDescription">
                    {aboutContent ? aboutContent.mission_subtitle : ""}
                  </p>
                </div>

                <div className="col-md-4 projectDescription pl-0">
                  <img src={misson} className="img-fluid  mx-auto pb-2" alt="donation" />
                </div>
              </div>
              {loading ? (
                <List className="w-100" />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(aboutContent ? aboutContent.mission : ""),
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
        <div className="row my-4">
          <div className={`col-md-7 mt-4`}>
            <div className="item p-4">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="h3 sectionTitle">
                    {aboutContent ? aboutContent.trust_title : ""}
                  </h1>
                  <p className="projectDescription">
                    {aboutContent ? aboutContent.trust_subtitle : ""}
                  </p>
                </div>
              </div>
              {loading ? (
                <List className="w-100" />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(aboutContent ? aboutContent.trust : ""),
                  }}
                ></div>
              )}
            </div>
          </div>
          <div className="col-md-5 mt-4">
            <div className="item p-4 mb-md-0 mb-4">
              <div className="row">
                <div className="col-md-8">
                  <h1 className="h3 sectionTitle">
                    {aboutContent ? aboutContent.strategy_title : ""}
                  </h1>
                  <p className="col-md-5 projectDescription pl-0 pr-0">
                    {aboutContent ? aboutContent.strategy_subtitle : ""}
                  </p>
                </div>
              </div>

              {loading ? (
                <List className="w-100" />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(aboutContent ? aboutContent.strategy : ""),
                  }}
                ></div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
