import React, { useState, useEffect, useRef } from "react";
import { CallApi } from "./../../Api/index";
import Header from "./header/Header";
import Department from "./department/Department";
import { Carousel } from "react-bootstrap";
import Projects from "./Projects/Projects";
import WhyAreWe from "./whyAreWe/WhyAreWe";

export default function Home() {
  const [homeContent, setHomeContent] = useState(null);
  const [slider, setSlider] = useState([]);
  const [youtube, setYoutube] = useState([]);
  const [loading, isLoading] = useState(true);
  const myRef = useRef();

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await CallApi(
          "GET",
          `/api/content/types/home_page?locale=${localStorage.getItem("rcml-lang")}`
        );

        setHomeContent(JSON.parse(response.data.data.content));
        setSlider(response.data.slider);
        setYoutube(response.data.youtube);
        isLoading(false);
      } catch (error) {
        // Ignore Error Message
        isLoading(false);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="HomePage">
      <Header
        headerTitle={homeContent ? homeContent.title : ""}
        description={homeContent ? homeContent.description : ""}
        loading={loading}
        myRef={myRef}
        images={slider ? slider : []}
      />
      <Department
        reference={myRef}
        departemntTitle={homeContent ? homeContent.departments_title : ""}
        description={homeContent ? homeContent.departments : ""}
        loading={loading}
      />
      <div className="row d-flex justify-content-center align-items-center my-5">
        <div className="col-md-6">
          <Carousel className="slider-wrapper rounded">
            {youtube && youtube.map((video) => {
              return (
                <Carousel.Item
                  key={video.video}
                  interval={null}
                  className="overflow-hidden"
                >
                  <div className="video-responsive mt-1 mb-1 rounded">
                    <iframe
                      width="853"
                      height="400"
                      src={`https://www.youtube.com/embed/${video.video}`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={video.title}
                    />
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        <div className={`col-md-4 mt-2 text-center ${window.innerWidth < 600 ? "mt-5" : ''}`}>
          <iframe 
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FSadaqahti%2F&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=569094316860404" 
            width="350"
            height="450"
            style={{ border:"none", overflow:"hidden" }} 
            scrolling="no" 
            frameBorder="0"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
          </iframe>
        </div>
      </div>
      <Projects
        title={homeContent ? homeContent.featured_projects_title : ""}
        description={homeContent ? homeContent.featured_projects : ""}
        loading={loading}
      />
      <Projects
        title={homeContent ? homeContent.gift_projects_title : ""}
        description={homeContent ? homeContent.gift_projects : ""}
        loading={loading}
        is_gift
      />
      <WhyAreWe
        loading={loading}
        title={homeContent ? homeContent.aids_title : ""}
        description={homeContent ? homeContent.aids_description : ""}
      />
    </div>
  );
}
