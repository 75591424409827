import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Select from "react-select";
import { scroller } from "react-scroll";
import { dictionaryList } from "../../languages/index";
import FastDonationModal from "./FastDonationModal";

// assets
import path_tree from "../../img/path_border_header.png";
import complete_path from '../../img/complate_path.png';
import en from "../../img/uk.png";
import de from "../../img/gr.png";
import ar from "../../img/ar.png";
import SocialLinks from "./SocialLinks";

const languagesLogo = {
  en: en,
  ar: ar,
  de: de,
};

export default function Navbar({ languages, currencies }) {
  const [menuBar, setmenuBar] = useState(false);
  const [defaultLanguage, setDefaultLanguage] = useState();
  const [defaultCurrency, setDefaultCurrency] = useState();
  const logo = localStorage.getItem("logo");
  const lang = localStorage.getItem("rcml-lang");
  const currency = localStorage.getItem("currency");
  // set html lang attribute
  document.getElementsByTagName("html")[0].setAttribute("lang", lang);

  useEffect(() => {
    setDefaultLanguage({
      value: lang,
      label: (
        <div className="d-flex justify-content-center">
          <img
            src={languagesLogo[lang]}
            width="25px"
            height="25px"
            className="rounded"
            alt="english"
          ></img>
        </div>
      ),
    });
  }, [lang]);

  useEffect(() => {
    setDefaultCurrency({
      value: currency,
      label: currency
    });
  }, [currency]);

  const handleLangChange = (selectedLang) => {
    const [currentLang] = document.location.pathname.replace(/^\//, "").split("/");
    localStorage.setItem("rcml-lang", selectedLang.value);
    document.location.pathname = document.location.pathname.replace(currentLang, selectedLang.value);
  };

  const handleCurrencyChange = (selectedCurrency) => {
    localStorage.setItem("currency", selectedCurrency.value);
    window.location.reload();
  };

  const menu = () => {
    setmenuBar(!menuBar);
  };

  const scrollToSection = () => {
    scroller.scrollTo("Department", {
      duration: 10,
      delay: 10,
      smooth: true,
    });
  };

  // spy event
  const simualteDonationClick = () => {
    menu();
    scrollToSection();
  }

  const options = languages.map((lang) => {
    const image = `${process.env.REACT_APP_URL}/${lang.flag}`;
    return {
      value: lang.slug,
      label: (
        <div className="d-flex justify-content-center" key={lang.id}>
          <img
            src={image}
            height="24px"
            width="24px"
            alt="countryFlag"
            className="rounded text-center"
          />
        </div>
      ),
    };
  });

  const currencyOptions = currencies.map((currency) => ({
    value: currency.symbol,
    label: currency.symbol
  }))

  return (
    <nav className={`navbar navbar-expand-lg navbar-light fixed-top main-nav ${window.innerWidth < 700 ? '' : 'mt-num'}`}>
      <div className="container flex-nowrap">
        <Link className="navbar-brand d-block" to={`/${lang}/`}>
          {logo ? (
            <img
              src={logo}
              className="img-fluid"
              alt="donation"
              width="123"
              height="39"
              style={{
                width: 130,
                height: 38,
                overflow: "hidden",
                objectFit: "cover",
              }}
            />
          ) : null}
        </Link>
        <div className="d-flex">
          <div className="d-block d-lg-none">
              <SocialLinks />
          </div>
          <button
            onClick={menu}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>

        {menuBar ? (
          <section className="content content-menu content-menu--aside mb-5">
            <div className="mobile-menu-header p-3 px-2">
              <i onClick={menu} className="fas fa-arrow-left float-right"></i>
              
              <div className="d-flex justify-content-center">
                <div className="text-center menu__item mobile-select p-0 border-0" style={{ width: 100, margin: '0 5px', fontSize: 12, fontWeight: 'bold' }}>
                  <Select
                    options={currencyOptions}
                    onChange={handleCurrencyChange}
                    value={defaultCurrency}
                    isSearchable={false}
                  />
                </div>
                <div className="text-center menu__item mobile-select p-0 border-0" style={{ width: 100, margin: '0 5px' }}>
                  <Select
                    options={options}
                    onChange={handleLangChange}
                    value={defaultLanguage}
                    isSearchable={false}
                  />
                </div>
              </div>
            </div>


            <nav className="navbar-nav px-2">
              <div className="fast-donate__mobile text-center my-3">
                <FastDonationModal />
              </div>
              <NavLink onClick={menu} exact as={Link} className="nav-link nav-link__mobile" activeStyle={{ fontWeight: "bold", color: "black" }} to={`/${lang}/`}>
                <span className="menu__item-name">{dictionaryList[lang].home}</span>
              </NavLink>
              <NavLink onClick={simualteDonationClick} exact className=" nav-link nav-link__mobile" to={`/${lang}/#donations`} activeStyle={{ fontWeight: "bold", color: "black" }}>
                <span className="menu__item-name">{dictionaryList[lang].donations_types}</span>
              </NavLink>
              <NavLink onClick={menu} as={Link} className="nav-link nav-link__mobile" to={`/${lang}/About`} activeStyle={{ fontWeight: "bold", color: "black" }}>
                <span className="menu__item-name">{dictionaryList[lang].about_us}</span>
              </NavLink>
              <NavLink onClick={menu} as={Link} className="nav-link nav-link__mobile" to={`/${lang}/projects`} activeStyle={{ fontWeight: "bold", color: "black" }}>
                <span className="menu__item-name">{dictionaryList[lang].projects}</span>
              </NavLink>
              <NavLink onClick={menu} as={Link} className="nav-link nav-link__mobile" to={`/${lang}/Contact`} activeStyle={{ fontWeight: "bold", color: "black" }}>
                <span className="menu__item-name">{dictionaryList[lang].contact_us}</span>
              </NavLink>
              <NavLink onClick={menu} as={Link} className="nav-link nav-link__mobile" to={`/${lang}/policy`} activeStyle={{ fontWeight: "bold", color: "black" }}>
                <span className="menu__item-name">{dictionaryList[lang].policy}</span>
              </NavLink>
            </nav>
            <img src={path_tree} className="img-fluid img-absolute" alt="donation" />
            <img src={complete_path} className="img-fluid img-absolute" alt="donation" />
          </section>
        ) : null}
        <div className="collapse navbar-collapse d-none d-lg-block" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto text-center py-2">
            <li className="nav-item mx-auto pl-2">
              <NavLink
                to={`/${lang}/`}
                className="nav-link"
                as={Link}
                exact
                style={{ color: "#7a828b", fontWeight: "bold" }}
                activeStyle={{ fontWeight: "bold", color: "black" }}
              >
                {dictionaryList[lang].home}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                onClick={scrollToSection}
                to={`/${lang}/`}
                className="nav-link"
                style={{ color: "#7a828b", fontWeight: "bold" }}
              >
                {dictionaryList[lang].donations_types}
              </NavLink>
            </li>
            <li className="nav-item mx-auto pl-2">
              <NavLink
                to={`/${lang}/sadaqah`}
                className="nav-link"
                as={Link}
                style={{ color: "#7a828b", fontWeight: "bold" }}
                activeStyle={{ fontWeight: "bold", color: "black" }}
              >
                {dictionaryList[lang].projects}
              </NavLink>
            </li>
            <li className="nav-item mx-auto pl-2">
              <NavLink
                to={`/${lang}/about`}
                className="nav-link"
                as={Link}
                style={{ color: "#7a828b", fontWeight: "bold" }}
                activeStyle={{ fontWeight: "bold", color: "black" }}
              >
                {dictionaryList[lang].about_us}
              </NavLink>
            </li>
            <li className="nav-item pl-2">
              <NavLink
                to={`/${lang}/contact`}
                className="nav-link"
                as={Link}
                style={{ color: "#7a828b", fontWeight: "bold" }}
                activeStyle={{ fontWeight: "bold", color: "black" }}
              >
                {dictionaryList[lang].contact_us}
              </NavLink>
            </li>
            <li className="nav-item pl-2">
              <NavLink
                to={`/${lang}/policy`}
                className="nav-link"
                as={Link}
                style={{ color: "#7a828b", fontWeight: "bold" }}
                activeStyle={{ fontWeight: "bold", color: "black" }}
              >
                {dictionaryList[lang].policy}
              </NavLink>
            </li>
          </ul>

          <ul className={`navbar-nav ${lang === 'ar' ? 'mr-auto' : 'ml-auto'}`}>
            <li className="nav-item" style={{ margin: '0 5px' }}>
              <FastDonationModal />
            </li>
            <li className="nav-item d-none d-lg-block" style={{ width: 60, margin: '0 5px' }}>
              <div className="fb-like" data-href="https://www.facebook.com/Sadaqahti" data-width="" data-layout="box_count" data-action="like" data-size="small" data-share="false"></div>
            </li>
            <li className="nav-item d-none d-lg-block" style={{ width: 60, margin: '0 5px', fontSize: 12, fontWeight: 'bold' }}>
              <Select
                options={currencyOptions}
                onChange={handleCurrencyChange}
                value={defaultCurrency}
                isSearchable={false}
              />
            </li>
            <li className="nav-item" style={{ width: 60, margin: '0 5px' }}>
              <Select
                options={options}
                onChange={handleLangChange}
                value={defaultLanguage}
                isSearchable={false}
              />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
