import React from "react";
import { List } from "react-content-loader";
import { Carousel } from "react-bootstrap";

import { dictionaryList } from "./../../../languages/index";
import Button from "../../base/Button";

import arrow from "../../../img/arrow.png";
import path_border_header from "../../../img/path_border_header.png";

export default function Header({ headerTitle, description, loading, myRef: ref, images }) {
  const lang = localStorage.getItem("rcml-lang");

  const scrollToDiv = () => {
    let scrollWay = window.matchMedia("(max-width:600px)");
    let node = ref.current;
    let nodeRect = node.getBoundingClientRect();
    if (scrollWay.matches) {
      window.scrollTo(0, node.scrollIntoView());
    } else {
      window.scrollTo(0, nodeRect.top);
    }
  };

  const align = lang === "ar" ? "right" : "left";
  const fullTitle = headerTitle
    .split(" ")
    .slice(0, headerTitle.length - 1)
    .join(" ");
  const lastWord = headerTitle.split(" ")[headerTitle.length - 1];

  return (
    <div className="Header pt-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-md-5 mt-md-4 textWrapper">
            {loading ? (
              <List width={530} className="mt-md-5 w-100" />
              ) : (
                <>
                <h1 className={`mt-md-5 headerTitle text-md-${align} text-center`}>
                  {fullTitle} <span className="last-word-title">{lastWord}</span>
                </h1>

                <p className="mt-3 headerDescribtion">
                  {description}
                </p>
                <div className="row btns-wrapper">
                  <div className="col-xs-6 pl-4 pr-2">
                    <Button
                      location="about"
                      state={{ status: "Execution" }}
                      styleClassName="mt-md-5"
                      lang={lang}
                      text="about_us"
                    />
                  </div>
                  <div className="col-xs-6 pl-2 pr-2 btn btn-success mt-md-5" id="donation" onClick={scrollToDiv}>
                    {dictionaryList[lang].donate_now_btn}
                    <img src={arrow} className="img-fluid  arrowHeader" alt="donation" />
                  </div>
                </div>
              </>
            )}
            <div className="col-md-6 headerArrowsWrapper d-none d-lg-block">
              <div className="arrow mt-5 mb-5 d-none d-lg-block ">
                <span className="d-none d-lg-block "></span>
                <span className="d-none d-lg-block "></span>
                <span className="d-none d-lg-block "></span>
              </div>
            </div>
          </div>
          <div className="col-md-7 mt-md-4 headerImageWrapper">
            <Carousel className="rounded overflow-hidden mb-4">
              {images.map((img, idx) => {
                return (
                  <Carousel.Item
                    key={idx}
                    interval={5000}
                    className="rounded overflow-hidden"
                  >
                    <img
                      className="d-block img-fluid w-100 overflow-hidden"
                      src={img.url}
                      alt={img.description}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      <img src={path_border_header} className="img-fluid" alt="donation" />
    </div>
  );
}
