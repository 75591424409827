import React from "react";
import Select from "react-select";

export default function CustomSelect({ value, onChange, options, ...rest }) {
  return (
    <Select
      defaultValue={options && options[options.findIndex((option) => option.value === value)]}
      onChange={onChange}
      options={options}
      {...rest}
    />
  );
}
