import React, { useEffect, useState } from "react";
import {useLocation } from "react-router-dom";

import { CallApi } from "./../../Api/index";
import { dictionaryList } from "./../../languages/index";
import ProjectSummary from "./projectSummary";
import Spinner from "../base/Spinner";
import CustomSelect from "../base/CustomSelect";

import path_title from "../../img/path_title.svg";
export default function ProjectsPage(props) {
  const [projects, setProjects] = useState(null);
  const [categories, setCategories] = useState(null);
  const [countries, setCountries] = useState(null);
  const [projectContent, setProjectContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(
    props.location.state ? props.location.state.categoryId : null
  );
  const [selectedCountry, setSelectedCountry] = useState(null);
  const lang = localStorage.getItem("rcml-lang");
  const location = useLocation();

  async function fetchCategories() {
    try {
      const response = await CallApi("GET", `/api/categories?locale=${lang}`);
      setCategories(response.data);
    } catch (error) {
      // Ignore Error Message
    }
  }

  async function fetchCountries() {
    try {
      const response = await CallApi("GET", `/api/countries?locale=${lang}`);
      setCountries(response.data);
    } catch (error) {
      // Ignore Error Message
    }
  }

  async function fetchProjectContent() {
    try {
      const response = await CallApi("GET", `/api/content/types/projects_page?locale=${lang}`);
      setProjectContent(JSON.parse(response.data.data.content));
    } catch (error) {
      // Ignore Error Message
    }
  }

  useEffect(() => {
    document.body.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    fetchProjectContent();
    fetchCategories();
    fetchCountries();
  }, []);

  // filter projects based on specifi filter [country, category, status]
  useEffect(() => {
    async function getFilteredProjects(filter) {
      try {
        setLoading(true);
        const response = await CallApi(
          "GET",
          `/api/projects?locale=${lang}&include=donations,projectCategory,village.country,coverMedia,galleryMedia,projectShortcuts&filter[disabled]=0${filter}`
        );
        setProjects(response.data);
        setLoading(false);
      } catch (error) {
        // Ignore Error Message
      }
    }

    const filter = `${selectedCountry ? `&filter[village.country_id]=${selectedCountry}` : ""}${
      selectedCategory ? `&filter[project_category_id]=${selectedCategory}` : ""
    }`;

    getFilteredProjects(filter);
  }, [lang, selectedCategory, selectedCountry]);

  // filter by country
  const onChangeCountryHandler = (country) => {
    setSelectedCountry(country ? country.value : null);
  };

  // filter by category
  const onChangeCategoryHandler = (category) => {
    setSelectedCategory(category ? category.value : null);
  };

  return (
    <div className="ProjectComPage py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="title-section pb-4">
              <img src={path_title} className="img-fluid" alt="donation" />
              <div className="content-title">
                <h1 className="h3 sectionTitle">{projectContent ? projectContent.title : ""}</h1>
                <p className="line-clamp">{projectContent ? projectContent.description : ""}</p>
              </div>
            </div>
          </div>
          <div className="col-md-8 pt-4 py-0">
            <div className="row justify-content-end align-items-center d-none">
              <div className="col-md-3 bg-light select-options rounded py-2 text-center mx-1">
                <label>{dictionaryList[lang].select_project_type}</label>
                {categories && (
                  <CustomSelect
                    value={selectedCategory}
                    placeholder={dictionaryList[lang].select}
                    onChange={onChangeCategoryHandler}
                    options={categories.map((category) => ({
                      value: category.id,
                      label: category.name,
                    }))}
                    isClearable={true}
                  />
                )}
              </div>

              <div className="col-md-3 bg-light select-options rounded py-2 text-center">
                <label>{dictionaryList[lang].select_project_country}</label>
                <CustomSelect
                  value={selectedCountry}
                  placeholder={dictionaryList[lang].select}
                  onChange={onChangeCountryHandler}
                  options={
                    countries &&
                    countries.map((country) => ({ value: country.id, label: country.name }))
                  }
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          {loading ? (
            <Spinner />
          ) : (
            <div className="col-12">
              <div className="row mt-4">
                {projects.data.map((project) => (
                  <ProjectSummary key={project.id} {...project} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
