import React from 'react'

function SocialLinks({ classNames = '' }) {
  const links = JSON.parse(localStorage.getItem("links"));
  return (
    <React.Fragment>
      {links && links.filter((link) => link.key !== 'home_youtube').map((link) => (
        <li className={`list-inline-item m-2 ${classNames}`} key={link.id}>
          <a
            href={link.value}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={`fab fa-${link.key.split("_")[1]}`}></i>
          </a>
        </li>
      ))}
    </React.Fragment>
  )
}

export default SocialLinks;
