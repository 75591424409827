import React from "react";
import { List } from "react-content-loader";
import DOMPurify from "dompurify";
import { dictionaryList } from "./../../languages/index";
import { getCurrencyExchange } from "../../helpers/getCurrencyExchange";
import {Carousel} from "react-bootstrap";

const InfoProject = ({
  name,
  cost,
  village,
  description,
  duration,
  loading,
  flag,
  cause,
  is_general,
  youtube,
  result
}) => {
  const lang = localStorage.getItem("rcml-lang");

  return (
    <div className="InfoProject p-4 mt-4">
      {loading ? (
        <List className="w-100" />
      ) : (
        <React.Fragment>
          <div className="row">
            <div className="col-md-10">
              <h1 className="h3 font-weight-bold"> {name} </h1>
            </div>
          </div>

          {is_general === 0 ? (
            <div className="row mt-4">
              <div className="col-md-4 col-6">
                <small className="text-muted d-block ">{dictionaryList[lang].project_value}</small>
                <span className="bg-warning text-small p-1 mt-1 text-center rounded d-block">
                  <strong>
                    {getCurrencyExchange(cost)}
                  </strong>
                </span>
              </div>
              <div className="col-md-4 col-6 ">
                <small className="text-muted d-block ">{dictionaryList[lang].duration}</small>
                <span className="mt-2 rounded d-block font-weight-bold">{`${
                  duration ? duration : ""
                } ${dictionaryList[lang].months}`}</span>
              </div>
              <div className="col-md-4 mt-4 mt-sm-0">
                <small className="text-muted d-block ">{dictionaryList[lang].country}</small>
                <span className="mt-2 rounded d-inline-block font-weight-bold">{village.country.name}</span>
                {village?.country?.flag ? (
                  <img
                    src={village?.country?.flag}
                    className={`img-fluid ${
                      lang === "ar" ? "float-left" : "float-right"
                    } mt-n3 county-name`}
                    alt="donation"
                  />
                ) : null}
              </div>
            </div>
          ) : null}

          <h3 className="h4 mt-4 font-weight-bold">{dictionaryList[lang].details_project}</h3>
          <div
            className="projectDescription"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
          ></div>
          <h3 className="h4 mt-4 font-weight-bold">{dictionaryList[lang].project_cause}</h3>
          <div
            className="projectDescription"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(cause) }}
          ></div>

          {youtube && youtube[0] ? (
              <React.Fragment>
          <h3 className="h4 mt-4 font-weight-bold">{dictionaryList[lang].project_videos}</h3>
          <Carousel className="slider-wrapper rounded">
            {youtube && youtube.map((video) => {
              return (
                  <Carousel.Item
                      key={video.video}
                      interval={null}
                      className="overflow-hidden"
                  >
                    <div className="video-responsive mt-4 rounded">
                      <iframe
                          width="560"
                          height="315"
                          src={`https://www.youtube.com/embed/${video.video}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title={video.title}
                      />
                    </div>
                  </Carousel.Item>
              );
            })}
          </Carousel>
              </React.Fragment>
          ) : null}

          {result ? (
            <React.Fragment>
              <h3 className="h4 mt-4 font-weight-bold">{dictionaryList[lang].project_result}</h3>
              <div
                className="projectDescription"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result) }}
              ></div>
            </React.Fragment>
          ) : null}
        </React.Fragment>
      )}
    </div>
  );
};

export default InfoProject;
