import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CallApi } from "./../../Api/index";
import { dictionaryList } from "./../../languages/index";
import { getCurrencyExchange } from "../../helpers/getCurrencyExchange";
import Spinner from "../base/Spinner";

import visa from "../../img/visa.svg";
import paypal from "../../img/paypal.svg";
import mastercard from "../../img/mastercard.svg";

export default function Payment({ name, sentAmount, is_gift }) {
  const [paymentStatus, setPaymentStatus] = useState("visa");
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const lang = localStorage.getItem("rcml-lang");
  const currency = localStorage.getItem("currency");

  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorMessage(null);
    }, 3000);

    // cleanup
    return () => clearTimeout(timer);
  }, [errorMessage]);

  const FNpaymentPaypall = () => {
    setPaymentStatus("paypal");
  };

  const FNpaymentVisa = () => {
    setPaymentStatus("visa");
  };

  const onRedirectEvent = () => {
    window.gtag('event', 'internal_whatsapp_button', {'send_to': 'G-PD3S9TK0V8'});
  }

  const onSubmitHandler = async (values, { resetForm }) => {
  //   try {
  //     // get rate
  //     const { rate } = currencies.find(
  //       curr => curr.symbol === currency
  //     );
  //     const response = await CallApi("POST", `/api/donate`, '', {
  //       ...values,
  //       project_id: project.id,
  //       amount: Number(
  //           parseFloat(values.amount / rate).toFixed(2)
  //         )
  //     });
  //     window.location.replace(response.data.paypal_link);
  //   } catch(error) {
  //     // Error Message
  //     setErrorMessage("Something went wrong!");
  //     resetForm();
  //   }
  };

  // if user does not choose a donation amount 
  // return minimum based on currency
  const getMinimumAmount = () => {
    switch(currency) {
      case 'EGP':
        return 100;
      default: 
        return 50;
    }
  }

  const PaymentSchema = Yup.object().shape({
    name: Yup.string().min(3).max(50).required(dictionaryList[lang].name_error),
    email: Yup.string().email("Invalid email!").required(dictionaryList[lang].email_error),
    phone: Yup.string().min(9, "invalid phone number").required(dictionaryList[lang].phone_error),
    amount: Yup.number().required(dictionaryList[lang].amount_error),
    note: Yup.string() 
  });

  const { values, errors, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      note: "",
      amount: sentAmount ? sentAmount : getMinimumAmount(), // min $$ to donate with.
    },
    validationSchema: PaymentSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: onSubmitHandler,
  });

  return (
    <div className="payment p-4 pb-5 mb-4">
      <div className="row">
        <div className="col-md-7 font-weight-bold">
          <h3>{dictionaryList[lang].payment_methods}</h3>
        </div>
        <div className="col-md-5 col-12" onClick={FNpaymentVisa}>
          <img src={visa} className="img-fluid mx-3 d-inline-block ml-auto mt-2" alt="donation" />
          <img src={mastercard} className="img-fluid d-inline-block mx-3 mt-2" alt="donation" />
          <img
            onClick={FNpaymentPaypall}
            src={paypal}
            className="img-fluid d-inline-block ml-auto mt-2"
            alt="donation"
          />
        </div>
      </div>
      <div className="reach-us text-center mt-5">
        <a href={`https://api.whatsapp.com/send?phone=265997650775&text=${is_gift ? dictionaryList[lang].gift_sentence : dictionaryList[lang].donate_sentence} ${sentAmount} ${currency} ${dictionaryList[lang].to} ${name}`}
           target="blank"
           onClick={onRedirectEvent}>
          <i className="fab fa-whatsapp mx-2"></i>
          {dictionaryList[lang].reach_us}
        </a>
      </div>
      {/* <form id="paymentForm" onSubmit={handleSubmit}>
        <div className="header-form mt-5 bg-light p-4">
          <div className="row">
            {loading ? (
              <Spinner animation="border" role="status" className="mx-auto">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              <>
                <div className="col-md-6">
                  <div className="form-group row">
                    <label htmlFor="name" className="col-sm-6 col-form-label projectDescription">
                      {dictionaryList[lang].project_name}
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        id="value"
                        defaultValue={project.name}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                {project.is_general === 0 ? (
                  <div className="col-md-6">
                    <div className="form-group row">
                      <label
                        htmlFor="remaining"
                        className="col-sm-6 col-form-label projectDescription"
                      >
                        {dictionaryList[lang].remianing}
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="remaining"
                          defaultValue={remainingAmount ? remainingAmount : null}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="name" className="projectDescription">
                {dictionaryList[lang].name}
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder={dictionaryList[lang].placeholder_name}
                value={values.name}
                onChange={handleChange}
              />
            </div>
            <p style={{ color: "red" }}>{errors.email ? errors.email : null}</p>
          </div>
          <div className="col-md-6">
            <div className="form-group mt-4">
              <label htmlFor="phone" className="projectDescription">
                {dictionaryList[lang].phone}
              </label>
              <PhoneInput
                country='eg'
                value={values.phone}
                onChange={handleChange}
                placeholder={dictionaryList[lang].placeholder_phone}
              />
            </div>
            <p style={{ color: "red" }}>{errors.phone ? errors.phone : null}</p>
          </div>
          <div className="col-md-12">
            <div className="form-group mt-4">
              <label htmlFor="email" className="projectDescription">
                {dictionaryList[lang].email}
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder={dictionaryList[lang].placeholder_email}
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <p style={{ color: "red" }}>{errors.name ? errors.name : null}</p>
            <div className="form-group mt-4">
              <label htmlFor="amount" className="projectDescription">
                {dictionaryList[lang].amount} ({currency})
              </label>
              <input
                type="number"
                className="form-control"
                id="amount"
                placeholder={dictionaryList[lang].placeholder_amount}
                value={values.amount}
                onChange={handleChange}
                min={1}
              />
            </div>
            <p style={{ color: "red" }}>{errors.amount && dictionaryList[lang].amount_error}</p>
            <div className="form-group">
              <label htmlFor="note" className="projectDescription">
                {dictionaryList[lang].project_details_note_title}
              </label>
              <textarea
                name="note"
                id="note"
                cols="30"
                className="form-control"
                placeholder={dictionaryList[lang].placeholder_message}
                rows="6"
                value={values.note}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row mt-md-5 mt-4">
          <div className="col-md-3 mt-3 mt-md-0">
            <button className="btn btn-success btn-block" type="submit" disabled={isSubmitting}>
              {isSubmitting ? <Spinner /> : dictionaryList[lang].submit}
            </button>
          </div>
        </div>
        {errorMessage && (
          <div className="alert alert-danger my-3">
            <strong>Error!</strong> {errorMessage}
          </div>
        )}
      </form> */}
    </div>
  );
}
