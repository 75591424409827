// get ( amount, selectedCurrency, currencies ) as arguments
// returns amount converted based on exchange rate

export const getCurrencyExchange = (amount) => {
  // access local storage infor
  const selectedCurrency = localStorage.getItem("currency");
  const currencies = JSON.parse(localStorage.getItem("currencies"));


  const currency = currencies ? 
    currencies.find(currency => currency.symbol === selectedCurrency)
    : { symbol: "USD", rate: "1.000" };

  return `${parseFloat(amount * currency.rate).toFixed(2)} ${selectedCurrency}` 
};

