let axios = require("axios");
const baseURL = `${process.env.REACT_APP_URL}`;
export function CallApi(method, url, params, data) {
  return axios({
    method,
    baseURL,
    url,
    params,
    data,
  })
    .then((response) => {
      return response;
    })
    .catch(function (error) {
      if (!error) {
        console.log(error.response);
      }
      return error.response;
    });
}
