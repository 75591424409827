import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { List } from "react-content-loader";
import { CallApi } from "../../Api/index";
import { dictionaryList } from "../../languages/index";
import SocialLinks from './SocialLinks';

export default function Footer() {
  const [footerContent, setFooterContent] = useState(null);
  const [loading, isLoading] = useState(true);
  const lang = localStorage.getItem("rcml-lang");
  const logo = localStorage.getItem("logo");

  useEffect(() => {
    async function fetchFooterData() {
      try {
        const response = await CallApi("GET", `/api/content/types/home_page?locale=${lang}`);
        setFooterContent(JSON.parse(response.data.data.content));
        isLoading(false);
      } catch (error) {
        //Ignore Error Message
        isLoading(false);
      }
    }

    // only fetch content when reloading
    // not in routes change
    if(!footerContent)
      fetchFooterData();
  }, [lang]);

  return (
    <div className="footer py-5">
      <div className="container">
        <div className="row border-bottom pb-4">

          <div className="col-md-3 text-center mt-3">
            {logo ? (
              <img
              src={logo}
                className="img-fluid d-block mx-auto"
                alt="donation"
                style={{
                  width: 175,
                  height: 55,
                  objectFit: "cover",
                  overflow: "hidden",
                }}
                />
            ) : null}
          </div>
          <div className="col-md-6">
            {loading ? (
              <List width={330} className="mt-md-5" />
            ) : (
              <div
                className="text-white text-center mt-4 mt-md-0"
                dangerouslySetInnerHTML={{
                  __html: footerContent ? footerContent.site_footer : "",
                }}
              ></div>
            )}
          </div>
          <div className="col-md-3 mt-3 text-center">
            <ul className="list-inline text-white text-center socialWrapper">
              <SocialLinks />
            </ul>
            {window.innerWidth < 700 && (
              <React.Fragment>
                <div 
                  className="fb-like" 
                  data-href="https://www.facebook.com/Sadaqahti" 
                  data-width="" 
                  data-layout="box_count" 
                  data-action="like" 
                  data-size="small" 
                  data-share="false">
                </div>
                <ul className="navbar-nav text-center p-0" style={{ color: "white" }}>
                  <li className="nav-item">
                    <i className="fa fa-phone fa-flip-horizontal mx-2"></i>&nbsp;الجوال  265997650775  
                  </li>
                  <li className="nav-item">
                    <i className="fa fa-at mx-2"></i>
                    <a href="mailto:support@sadaqahti.com">support@sadaqahti.com</a>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-md-8 text-center pt-3 footerPages">
            <ul
              className={`list-inline text-white footerList ${lang === "ar" ? "text-right" : ""}`}
            >
              <Link className="list-inline-item" to={`/${lang}/`}>
                {dictionaryList[lang].home}
              </Link>
              <Link className="list-inline-item" to={`/${lang}/About`}>
                {dictionaryList[lang].about_us}
              </Link>
              <Link className="list-inline-item" to={`/${lang}/sadaqah`}>
                {dictionaryList[lang].projects}
              </Link>
              <Link className="list-inline-item" to={`/${lang}/Contact`}>
                {dictionaryList[lang].contact_us}
              </Link>
              <Link className="list-inline-item" to={`/${lang}/policy`}>
                {dictionaryList[lang].policy}
              </Link>
            </ul>
          </div>
          <div className="col-md-4">
            <div className="text-white pt-3 d-block text-center text-md-right">
              {dictionaryList[lang].copyrights}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
