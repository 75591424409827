// get project ( cost, collected ) as arguments
// returns donation object conatins amount, percentage

export const calculateProjectDonation = (projectCost, collected) => {
  let cost = parseFloat(projectCost).toFixed(2);

  return collected >= cost
    ? {
        percentage: 100,
        remaining: 0.0,
      }
    : {
        percentage: Math.ceil((+collected / cost) * 100),
        remaining: parseFloat(cost - +collected).toFixed(2),
      };
};
