import React, { useState, useEffect } from 'react'
import { Button, InputGroup, FormControl } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';

import { dictionaryList } from "../../languages";

function DonateInput({ name, sentAmount, id, is_gift }) {
  const [amount, setAmount] = useState('');
  const history = useHistory();
  const lang = localStorage.getItem("rcml-lang");

  useEffect(() => {
    setAmount(sentAmount);
  }, [sentAmount])

  const onDonate = () => {
    history.push(`/${lang}/${id}/main-payment`, 
      { 
        sentAmount:  amount, 
        isProject: true, 
        category: { name },
        is_gift: is_gift
    });
  }

  return (
    <div className="anthor-donation ItemDonation bg-light mt-3 rounded p-4">
      <InputGroup className="mb-3 donate-group">
        <FormControl
          className="form form-control donate-group__input"
          placeholder={dictionaryList[lang].amount}
          aria-label="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
        <Button 
          id="basic-addon1" 
          variant="success" 
          className="rounded"
          onClick={onDonate}
          disabled={!!!amount}
        >
          <span>{is_gift ? dictionaryList[lang].gift : dictionaryList[lang].donate}</span>
          <i className="fa fa-paper-plane mx-1"></i>
        </Button>
      </InputGroup>
    </div>
  )
}

export default DonateInput
