import React from 'react'

function ShareIcons({ showShareIcon = false, projectUrl, projectName, className = '' }) {
  // current selected language
  const lang = localStorage.getItem("rcml-lang");

  // application base url
  const baseUrl = process.env.REACT_APP_BASE_URL;

  // base page url
  const ProjectRoute = `${baseUrl}${lang}/sadaqah/${projectUrl}`;

  return (
    <div className={`projectShare ${className}`}>
      {showShareIcon && <span><i className="fa fa-share-alt"></i></span>}
      <a 
        href={`https://twitter.com/intent/tweet?text=${projectName}&url=${ProjectRoute}`}
        className="tws" 
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-twitter"></i>
      </a>
      <a 
        href={`https://www.facebook.com/sharer.php?t=${projectName}}&u=${ProjectRoute}`}
        className="fbs" 
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fab fa-facebook"></i>
      </a>
      <a 
        href={`https://api.whatsapp.com/send?text=${ProjectRoute}`} 
        className="was" 
        target="_blank"
        rel="noopener noreferrer"
        
      >
        <i className="fab fa-whatsapp"></i>
      </a>
    </div>
  )
}

export default ShareIcons;
