import React from "react";
// import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";
// import ReactGA from 'react-ga';
import App from "./App";
import "./css/demo.css";
import "./css/style-ama.scss";
import "./styles/style.scss";
import "react-phone-input-2/lib/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import * as serviceWorker from "./serviceWorker";

// google anayltics
// ReactGA.initialize('UA-000000-01', {
//   debug: true,
//   titleCase: false,
//   gaOptions: {
//     userId: 123
//   }
// });
// ReactGA.pageview(window.location.pathname + window.location.search);

// this condition run only once when there is localStorage old and then updated and never run
if (!localStorage.getItem("clearStorage")) {
  localStorage.clear();
  localStorage.setItem("clearStorage", "<new version number>");
}

// check app default language
const localeCodes = ["en", "ar", "de"];
// first, get language from url
let [currentLang] = document.location.pathname.replace(/^\//, "").split("/");
// update url incase of no language provided
if (localeCodes.includes(currentLang)) {
  // check currentLang value
  currentLang = localeCodes.includes(currentLang) ? currentLang : localStorage.getItem("rcml-lang") || "en";
  // set language in locale storage
  localStorage.setItem("rcml-lang", currentLang);
} else {
  // set language in locale storage
  localStorage.setItem("rcml-lang", "en");
  document.location.pathname = `/en${document.location.pathname}`;
}

// check app default currency
const currentCurrency = localStorage.getItem("currency");
if (currentCurrency === null) {
  localStorage.setItem("currency", "USD")
}


// ReactDOM.render(<App />, document.getElementById("root"));
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
