import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { dictionaryList } from "./../../languages/index";

import donated_complated from "../../img/donated_complated.svg";
import path_Donated from "../../img/path_Donated.svg";

export default function Donated(props) {
  const [donationMessage, setDonationMessage] = useState("");
  const donationStatus = props.location.search.includes("code=200") ? "success" : "failed";
  const lang = localStorage.getItem("rcml-lang");
  const localeDonationMessages = {
    success: {
      en: "Donation is completed successfuly! Thank you for helping us",
      ar: "عملية التبرع نجحت، شكرا لكم لمساعدتنا",
    },
    failed: {
      en: "We're sorry, Donation process isn't successful!",
      ar: "عذرا، عملية التبرع لم تنجح",
    },
  };

  useEffect(() => {
    setDonationMessage(localeDonationMessages[donationStatus][lang]);
  }, [lang, donationStatus, localeDonationMessages]);

  return (
    <div className="payment Donated p-4">
      <div className="path_Donated">
        <img src={path_Donated} className="img-fluid" alt="donation" />
      </div>
      <img src={donated_complated} className="img-fluid d-block mx-auto mt-3 " alt="donation" />
      <h1 className="text-center mt-1 text-success">
        <small></small>
      </h1>
      <h3
        className="text-center"
        style={donationStatus === "sucess" ? { color: "Green" } : { color: "Red" }}
      >
        {donationMessage}
      </h3>
      <div className="row mt-5 text-center d-flex justify-content-center">
        <div className="col-md-8 text-center ">
          <Link to={`/${lang}/`} className="text-center mt-2 d-block bg-light">
            {dictionaryList[lang].back_homepage}
          </Link>
        </div>
      </div>
    </div>
  );
}
