import React, { useState, useEffect } from "react";
import { CallApi } from "./../../Api/index";
import { dictionaryList } from "./../../languages/index";

import ProjectSummary from "./../projectsPage/projectSummary";
import Payment from "./Payment";
import FastDonationModal from '../base/FastDonationModal';

import surface1 from "../../img/surface1.svg";

export default function MainPayment(props) {
  const [project, setProject] = useState(null);
  
  const lang = localStorage.getItem("rcml-lang");

  const { id } = props.match.params;

  const {
    isProject = true,
    category = null,
    sentAmount = 100,
    is_gift = false
  } = props.location.state;

  useEffect(() => {
    async function fetchProject() {
      try {
        const response = await CallApi("GET", `/api/projects/${id}?locale=${lang}&include=coverMedia`);
        setProject(response.data);
      } catch (error) {
        // Ignore Error Message
      }
    }
    if (isProject) {
      fetchProject();
    }
  }, [lang, id, isProject]);

  if (!props.location.state) {
    return <FastDonationModal append />
  }

  return (
    <div className="MainPayment py-5">
      <div className="container">
        <div className="row">
          <div className="col-md-9">
            <Payment name={isProject ? project?.name : category?.name} sentAmount={sentAmount} is_gift={is_gift} />
          </div>
          <div className="col-md-3">
            {isProject && project && (
              <ProjectSummary {...project} className="row" />
            )}
            <div className="ads bg-light-success rounded py-4 p-4">
              <div className="row">
                <div className="col-4">
                  <img src={surface1} className="img-fluid mt-2" alt="donation" />
                </div>
                <div className="col-8">
                  <h6 className="mb-0 mt-md-0 mt-3 pb-0 font-weight-bold">
                    {dictionaryList[lang].payment_note}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
