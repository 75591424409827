import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-router-scroll-top";
import { CallApi } from "./Api/index";

// base components
import Navbar from "./components/base/Navbar";
import TopNavbar from "./components/base/TopNavbar";
import Footer from "./components/base/Footer";

// pages
import Home from "./components/homepage/HomePage";
import Projects from "./components/projectsPage/ProjectsPage";
import ProjectDetails from "./components/projectsPage/ProjectDetails";
import MainPayment from "./components/payment/MainPayment";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Donated from "./components/payment/Donated";
import Policy from "./components/policy/Policy";
import NotFound from "./components/notFound/NotFound";
import WhatsAppWidget from "./components/base/WhatsAppWidget";

function App() {
  const [appLanguages, setAppLanguages] = useState([]);
  const [appCurrencies, setAppCurrencies] = useState([]);

  // fetch app languages
  useEffect(() => {
    async function fetchAppLanguages() {
      try {
        const response = await CallApi("GET", `/api/languages`);
        setAppLanguages(response.data.data);
      } catch (error) {
        // Ignore Error Message
      }
    }
    
    fetchAppLanguages();
  }, []);

  // fetch app currencies
  useEffect(() => {
    async function fetchAppCurrencies() {
      try {
        const response = await CallApi("GET", `/api/get_exchange_rates`);
        setAppCurrencies(response.data);
        localStorage.setItem("currencies", JSON.stringify(response.data));
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchAppCurrencies();
  }, []);
  

  // fetch app site general
  useEffect(() => {
    async function fetchAppGenerals() {
      try {
        const response = await CallApi("GET", `api/site-general`);
        const links = response.data.data;
        const logo = links.find((link) => link.key === "site_logo").value;
        const filteredLinks = links.filter(
          (link) => link.key !== "site_logo" && link.key !== "site_slider"
        );
        localStorage.setItem("links", JSON.stringify(filteredLinks));
        localStorage.setItem("logo", `${process.env.REACT_APP_URL}/storage/${logo}`);
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchAppGenerals();
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop>
          <TopNavbar />
          <Navbar languages={appLanguages} currencies={appCurrencies} />
          <div className="mt-5 pt-5">
            <Switch>
              <Route exact path="/:locale(en|ar|de)?/" component={Home} />
              <Route exact path="/:locale(en|ar|de)?/sadaqah" component={Projects} />
              <Route exact path="/:locale(en|ar|de)?/sadaqah/:projectId" component={ProjectDetails} />
              <Route exact path="/:locale(en|ar|de)?/:id/main-payment" component={MainPayment} />
              <Route exact path="/:locale(en|ar|de)?/about" component={About} />
              <Route exact path="/:locale(en|ar|de)?/contact" component={Contact} />
              <Route exact path="/:locale(en|ar|de)?/donated" component={Donated} />
              <Route exact path="/:locale(en|ar|de)?/policy" component={Policy} />
              <Route component={NotFound} />
            </Switch>
          </div>
          <WhatsAppWidget />
          <Footer />
        </ScrollToTop>
      </div>
    </BrowserRouter>
  );
}

export default App;
