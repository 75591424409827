import React, { useState, useEffect } from "react";
import { CallApi } from "./../../../Api/index";

import path_title from "../../../img/path_title.svg";

export default function WhyAreWe({ title, description }) {
  const [aidsContent, setAidsContent] = useState([]);
  const lang = localStorage.getItem("rcml-lang");

  useEffect(() => {
    async function fetchAidsContent() {
      try {
        const response = await CallApi("GET", `api/aids?locale=${lang}`);
        setAidsContent(response.data.data);
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchAidsContent();
  }, [lang]);

  return (
    <div className="WhyAreWe py-5">
      <div className="container big-wrapper">
        <div className="title-section pb-4 aidsMobile">
          <img src={path_title} className="img-fluid " alt="donation" />
          <div className="content-title aidsContentWrapper">
            <h1 className="h3 sectionTitle">{title}</h1>
            <p className="line-clamp">{description}</p>
          </div>
        </div>

        <div className="row mt-5">
          {aidsContent.map((aid) => {
            return (
              <div className="col-md-3 col-6" key={aid.id}>
                <div className="item text-center aidsPic  p-3 mb-5">
                  <img
                    src={aid.icon}
                    className="img-fluid d-block pl-5 ml-5 mt-3 "
                    alt="donation"
                  />
                  <h5 className="mt-3 aidsTitle">{aid.title}</h5>
                  <p className="mt-2 px-3 projectDescription"> {aid.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
