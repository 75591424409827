import React, { Fragment } from "react";
import { List } from "react-content-loader";

import { dictionaryList } from "../../languages/index";

import path_puplisher from "../../img/path_puplisher.svg";
import path_puplisher2 from "../../img/path_puplisher2.svg";

export default function PublisherInfo({ location, loading }) {
  const lang = localStorage.getItem("rcml-lang");

  return (
    <div className="PublisherInfo p-4 w-100 my-4">
      <div className="polygon">
        <img src={path_puplisher2} className="img-fluid" alt="donation" />
      </div>
      <div className="row">
        <div className="col-md-6">
          {loading ? (
            <List className="w-100" />
          ) : (
            <Fragment>
              <h6 className="mt-3 font-weight-bold">{dictionaryList[lang].location}</h6>
              <h4 className="font-weight-bold">{location.name}, {location.country.name}</h4>
            </Fragment>
          )}
        </div>
      </div>
      <div className="path_bottom">
        <img src={path_puplisher} className="img-fluid " alt="donation" />
      </div>
    </div>
  );
}
