import React from "react";

export default function About() {
  return (
    <div className="About py-5">
      <div className="container">
        <div className="direction-ltr text-left">
          <h1 className="h3 sectionTitle mb-2">Introduction</h1>
          <p>
            Fundraising is used to achieve the primary purpose of campaigns, 
            to participate in rapid responses, and to fulfill the organization's vision and mission of helping those 
            in need and preserving their dignity.
          </p>
          <h1 className="h3 sectionTitle mb-2">Fundraising Policy Procedures and Restrictions</h1>
          <p>To further the goal of fundraising, the procedures outlined below must be followed:</p>
          <p>The organization must raise funds in a respectful, clear and transparent manner.</p>
          <p>The organization has the right to hold meetings and conferences and invite many personalities to collect donations.</p>
          <p>Those who carry out fundraising activities (from the organization's employees) in the name of the organization are not entitled to receive personal gifts in any form.</p>
          <p>The organization must respect the restrictions and conditions of conditional campaigns (campaign objectives) during the collection, implementation and disbursement of donation amounts for those campaigns.</p>
          <p>As for unconditional donations (regardless of the means of their collection) they are dealt with in the unconditional public funding account, and accordingly the organization has the right to freely dispose of it according to its needs.</p>
          <p>Donations are collected through electronic campaigns that are promoted on the organization’s website and on the organization’s social networking pages through:</p>
          <p>The organization's electronic bank accounts</p>
          <p>The organization's bank accounts after taking the special approvals for that matter</p>
          <p>The financial department of the organization must issue financial reports to the management on all the funds collected and received for the campaigns that have been promoted (each campaign separately) and their expenditures after being audited by the fundraising official.</p>
          <p>The supporting departments in the organization must issue the final reports for each campaign upon completion of its implementation to be audited by the fundraising official before being approved by the administration.</p>
          <p>In some cases, the organization has the right to collect donations for campaigns through an intermediary or a third party.</p>
          <p>The campaigns that are launched on the organization’s website and the organization’s social media platforms must meet the following conditions:</p>
          <p>Campaign name</p>
          <p>Campaign target amount</p>
          <p>A simple explanation of the campaign and its goal</p>
          <p>As for the fourth condition, it varies according to the type of campaign, whether the campaign is seasonal or permanent.</p>
          <p>In the event that the campaign is seasonal, for example (campaigns for the winter season - campaigns for the holy month of Ramadan - campaigns for Eid al-Fitr or Eid al-Adha - sacrifice campaigns...) the end of the campaign must be mentioned so that the campaign is closed when one of the specified people is reached, either by reaching the amount The target of the campaign or the end of the period for collecting donations for that campaign.</p>
          <p>In the event that the campaign is permanent, for example (campaigns for education - campaigns for shelter for the displaced - campaigns for ambulance teams - campaigns to support the organization…..) here the end of the campaign is not mentioned so that the campaign is closed financially and the balance that has not been implemented is rolled over to the next cycle.</p>
          <p>With regard to in-kind donations, the organization has the right to receive in-kind donations from donors for use and implementation according to its own conditional goals.</p>
          <p>The organization is committed to applying all procedures, restrictions and conditions of this policy and the policies associated with it.</p>
          <h1 className="h3 sectionTitle mb-2">Communicate with donors</h1>
          <p>Within 7 days of receiving the donation, each donor will receive an official acknowledgment from the organization / thank you letter and a donation receipt, and donations of more than $2000 will also receive a personal phone call from the organization's general manager or fundraising officer if sufficient information is available about the donor to make So contact.</p>
          <p>Each new donor will be included in media correspondence and invitations to special events will be received.</p>
          <p>Donors who provide their postal address or email address can be contacted periodically to give them information about the campaigns.</p>
          <h1 className="h3 sectionTitle mb-2">Confidentiality and Confidentiality of Donor Information</h1>
          <p>Any information provided by the donor will not be used and will not be shared for any reason unless permission is obtained from the donor to share this information.</p>
          <p>All requests for anonymity of donors must be respected.</p>
          <p>All donors are placed in a mailing list and sent to them periodically, and they have the option to withdraw from the list whenever they want, by requesting permanent removal from the mailing list.</p>
          <p>Respect the wish of donors who provide the organization with their phone number not to be contacted for purposes of raising funds for current campaigns over the phone.</p>
          <p>In case of any inquiries or questions with the donors, please contact the fundraising official on the organization’s official email for donations: support@sadaqahti.com</p>
          <p>In some cases and cases, the donor can withdraw his donation either permanently or change the goal of the donation from one campaign to another, through:</p>
          <p>If the donation is through one of the organization’s electronic bank accounts:</p>
          <p>The donor can withdraw his donation permanently after deducting the electronic bank’s commission electronically without returning to the organization.</p>
          <p>
            The donor can change the destination of his donation from one campaign to another by withdrawing his donation from the first campaign after deducting the electronic bank commission and donating to the other campaign
            You can contact the fundraising official to change the destination of the donation from one campaign to another via the official email support@sadaqahti.com and inform the financial department of that change, within 48 hours of the donation.
          </p>
          <p>
            If the donation is made through one of the organization’s bank accounts:
          </p>
          <p>
            The donor cannot withdraw his donation permanently, and he cannot change the destination of his donation from one campaign to another.
          </p>
          The fundraising official cannot change the destination of the donation from one campaign to another, even if he has been contacted
        </div>
      </div>
    </div>
  );
}
