import React, { useState, useEffect } from "react";
import { CallApi } from "../../../Api";

import ProjectSummary from "../../projectsPage/projectSummary";

import path_title from "../../../img/path_title.svg";

export default function Projects({ title, description, is_gift = false }) {
  const [projects, setProjects] = useState([]);
  const lang = localStorage.getItem("rcml-lang");

  useEffect(() => {
    async function fetchProjects() {
      try {
        const response = await CallApi(
          "GET",
          `/api/projects?locale=${lang}&include=donations,projectCategory,village.country,coverMedia,galleryMedia,projectShortcuts${is_gift ? '&filter[is_gift]=1' : '&filter[is_featured]=1'}`

        );
        setProjects(response.data.data);
      } catch (error) {
        // Ignore Error Message
      }
    }

    fetchProjects();
  }, [lang]);

  return (
    <div className="CompletedProjects mb-md-5 mb-0">
      <div className="container">
        <div className="title-section-center pb-4 title-img-wrapper">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="title-section progressMobile">
                <img src={path_title} className="img-fluid" alt="donation" />
                <div
                  className="content-title progressContentWrapper"
                >
                  <h1 className="h3 sectionTitle">{title}</h1>
                  <p className="line-clamp">{description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          {projects.map((project) => <ProjectSummary {...project} key={project.id} /> )}
        </div>
      </div>
    </div>
  );
}
